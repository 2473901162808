import React, { Component } from "react";
import { connect } from "react-redux";

import sprintf from "sprintf-js";

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from '@material-ui/core/Checkbox';
import withStyles from "@material-ui/core/styles/withStyles";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import RegularButton from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Grid from '@material-ui/core/Grid';
import CardHeader from "components/Card/CardHeader.jsx";
import ParkSelector from "components/ParkSelector/ParkSelector";

import { buildAPIRequest, security_fetch_params } from "actions/index";
import LoginPage from "views/Login/Oops.jsx";
import localForage from 'localforage';
import moment from 'moment';
import {logout} from 'utils/auth';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import alertify from 'alertifyjs';

import "assets/css/ct.scss";

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportingData from 'highcharts/modules/export-data';

import classNames from 'classnames';


const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.loggedIn,
    conn: state.conn,
  };
};

const mapDispatchToProps = (dispatch) => ({});

const LF_CACHE_TTL = 60;

const CALC_LIMITS_TIMEOUT = 2000;

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255, .62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    marginBottom: '3px',
    textDecoration: 'none'
  },
  textField: {
    marginRight: '15px'
  },
  selectLabel: {
    paddingTop: '20px',
    paddingRight: '10px'
  },
  selectField: {
    paddingTop: '15px',
    marginRight: '15px',
  },
  checkboxLabel: {
    paddingTop: '40px',
  },
  checkBoxRoot: {
    paddingTop: '28px',
    borderRadius: '0px',
    paddingLeft: '0px',
  },
  useDaLimitAsGlobalDiv: {
    textAlign: 'right'
  },
  scheduleCell: {
    whiteSpace: 'nowrap',
    padding: 0,
    textAlign: 'center',
  },
  scheduleTableHeadCell: {
    textAlign: 'center'
  },
  scheduleTable: {
    tableLayout: "auto"
  },
  fullWidth: {
    width: '100%',
  },
};


class Config extends Component {
  BaseDA = 'Day-ahead';
  BaseID = 'Intraday (base)';
  BaseAU = 'Auction';
  OPID = 'Intraday (open position)';
  IDOX = 0;
  IDBX = 1;
  DABX = 2;

  constructor(props) {
    super(props);
    HighchartsExporting(Highcharts);
    HighchartsExportingData(Highcharts);

    const options = this.props.battery
      ? this.props.conn.parks.filter(el => el.name.includes("Battery"))
      : this.props.conn.parks.filter(el => !el.name.includes("Battery"));

    this.state = {
      park: null,
      limits: null,
      formdata: this._form_data(null),
      formDataStart: this._form_data(null),
      scheduleDate: {},
      useScheduleApply: {},
      useScheduleApplyDa: false,
      useScheduleApplyId: false,
      value: '',
      typing: false,
      typingTimeout: 0,
      options: options,
    };

    this._initial_load = this._initial_load.bind(this);
    this.load_park = this.load_park.bind(this);
    this.get_park_data = this.get_park_data.bind(this);
    this.get_limits = this.get_limits.bind(this);
    this.calc_limits = this.calc_limits.bind(this);
    this.calcLimitsResponse = this.calcLimitsResponse.bind(this);

    this.renderForm = this.renderForm.bind(this);
    this.calcFiledValue = this.calcFiledValue.bind(this);

    this._on_park_select = this._on_park_select.bind(this);
    this._on_reset = this._on_reset.bind(this);
    this._on_submit = this._on_submit.bind(this);
    this._on_schedule_apply = this._on_schedule_apply.bind(this);
    this._on_schedule_da_nom_change = this._on_schedule_da_nom_change.bind(this);

    this._on_change = this._on_change.bind(this);
    this._on_schedule_change = this._on_schedule_change.bind(this);
    this.getCurSchPmax = this.getCurSchPmax.bind(this);
    
    this._form_data = this._form_data.bind(this);
    this._reset_calc_limits_timer = this._reset_calc_limits_timer.bind(this);
    this._show_waiting = this._show_waiting.bind(this);
    this._show_complete = this._show_complete.bind(this);
    this._post_process_da_limits = this._post_process_da_limits.bind(this);
    this._calc_limits_cb = this._calc_limits_cb.bind(this);
    this._market_auction_hour = this._market_auction_hour.bind(this);
    this.handleChangeSchField = this.handleChangeSchField.bind(this);
    this.handleChangeCheckboxSch = this.handleChangeCheckboxSch.bind(this);

    // da, id timers that call calc_limits
    this.calc_limits_timer = null;

    this.abrt = new AbortController();
  };

  _setup_park(park_id, force, cb) {
    this.get_park_data(park_id, function (park) {
      var st = {};

      st['park'] = park;
      const formdata = this._form_data(park)
      st['formdata'] = JSON.parse(JSON.stringify(formdata));
      st['formDataStart'] = JSON.parse(JSON.stringify(formdata));
      //if today <= schedule date then we show user schedule Pmax
      // if(formdata) {
      //   const {keys, curSchedulePmax} = this.getCurSchPmax(st['formdata'].optimization_job);
      //   if(curSchedulePmax !== null) {
      //     if(keys === null) {
      //       st['formdata'].optimization_job.Pmax = curSchedulePmax;
      //     } else {
      //       keys.forEach((key, id) => {
      //         st['formdata'].optimization_job.entry_over[key].Pmax = curSchedulePmax[id];
      //       });
      //     }
      //   };
      // }
      this.get_limits(park,function (limits, responseLimits = null) {
        if (limits !== null) {
          st['limits'] = limits;
          st['responseLimits'] = responseLimits;
        }
        this.setState(st);
        if (cb !== null) {
          cb();
        }
      }.bind(this));
    }.bind(this), force);
  }

  // get Pmax from future_over data if it was set before today
  getCurSchPmax(optJob) {
    const entryOver = optJob.entry_over;
    const keys = [];
    const curSchedulesPmax = [];
    if(entryOver) {
      Object.keys(entryOver).forEach((key) => {
        if(entryOver[key].future_over) {
          const curScheduleDate = Object.keys(optJob.entry_over[key].future_over)[0];
          const curSchedulePmax = optJob.entry_over[key].future_over?.[curScheduleDate]['Pmax'];
          if (
            moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').unix() >=
            moment(curScheduleDate, 'YYYY-MM-DD').add(-1, 'days').unix()
          ) {
            keys.push(key)
            curSchedulesPmax.push(curSchedulePmax)
          }
        }
      });
      return {keys, curSchedulePmax: curSchedulesPmax}
    } else {
      if(optJob.future_over) {
        const curScheduleDate = Object.keys(optJob.future_over)[0];
        const curSchedulePmax = optJob.future_over?.[curScheduleDate]['Pmax'];
        if (
          moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').unix() >=
          moment(curScheduleDate, 'YYYY-MM-DD').add(-1, 'days').unix()
        ) {
          return {keys: null, curSchedulePmax}
        }
      }
    }
    return {keys: null, curSchedulePmax: null}
  }

  _remove_leading_zeros(s) {
    return parseInt(s, 10).toString(10);
  }

  /**
   * Prepare various schedules for display:
   *  - minutes: mm[,mm]*
   *  - minutes and hours from a cron-like pattern: "m h * * *" -> "hh:mm"
   *
   * Normalize all numbes to %02d.
   */
  _schedule_api2display(s) {
    if (s === null || s === '' || s === undefined) {
      return '';
    }

    let pieces = s.split(' ');
    let minutes = pieces[0];

    minutes = minutes.split(',').map(i => sprintf.sprintf('%02d', parseInt(i, 10))).join();

    if (pieces.length > 1) {
      // m h * * *
      let hours = pieces[1];

      if (hours === '*') {
        return minutes;

      } else {
        hours = hours.split(',').map(i => sprintf.sprintf('%02d', parseInt(i, 10))).join();
        return hours + ':' + minutes;
      }
    } else {
      return minutes;
    }
  }

  /**
   * Prepare various schedules for API:
   *  - minutes: m[,m]*
   *  - hour:minute: "hh:mm" -> "m h * * *"
   *
   * Normalize all numbers to leading zeros removed.
   */
  _schedule_display2api(s) {
    if (s === null || s === '') {
      return null;
    }

    let pieces = s.split(':');

    if (pieces.length > 1) {
      // hh:mm
      let hours = pieces[0];
      let minutes = pieces[1];

      if (minutes === '') {
        throw new Error({
          error: 'Invalid schedle setting: ' + s
        });
      }

      hours = hours.split(',').map(i => this._remove_leading_zeros(i)).join();
      minutes = minutes.split(',').map(i => this._remove_leading_zeros(i)).join();

      return minutes + ' ' + hours;

    } else {
      let minutes = pieces[0];
      minutes = minutes.split(',').map(i => this._remove_leading_zeros(i)).join();

      return minutes + ' ' + '*';
    }
  }

  _use_da_limit_api2display(v) {
    if (v === null) {
      return -1;
    } else if (!v) {
      return 0;
    } else {
      return 1;
    }
  }

  _use_da_limit_display2api(v) {
    if (v < 0) {
      return null;
    } else if (v === 0) {
      return false;
    } else {
      return true;
    }
  }

  _form_data(park) {
    if (park === null)
      return null;
    else 
      return ({
        optimization_job : {
          afternoon_run: park.optimization_job.afternoon_run,
          dt: park.optimization_job.dt,
          Pmax: park.optimization_job.Pmax,
          schedule_da_deliver: park.optimization_job.schedule_da_deliver,
          fixed_generation: (park.optimization_job.fixed_generation === null) ?
            -1 : park.optimization_job.fixed_generation,
          da_forecast_limit: park.optimization_job.da_forecast_limit,
          capacity_limit: park.optimization_job.capacity_limit,
          // XXX internally string
          liquidity_curve: JSON.stringify(park.optimization_job.liquidity_curve),
          hours_before_delivery: park.optimization_job.hours_before_delivery,
          max_afternoon_horizon: park.optimization_job.max_afternoon_horizon,
          intraday_treatment: (park.optimization_job.intraday_treatment === null) ?
            '' : park.optimization_job.intraday_treatment,
          mode: (park.optimization_job.mode === null) ?
            '' : park.optimization_job.mode,
          use_da_limit_as_global: this._use_da_limit_api2display(
            park.optimization_job.use_da_limit_as_global),
          future_over: park.optimization_job.future_over,
          entry_over: park.optimization_job.entry_over,
          deliver_nominations: park.optimization_job.deliver_nominations,
          beta: park.optimization_job.beta,
          base_ratio: park.optimization_job.base_ratio,
          alpha: park.optimization_job.alpha,
        },
        schedule: park.schedule,
      });
  };

  _on_schedule_da_nom_change = (e) => {
    const that = this;
    const eValue = {
      'target':{
        name: e.target.name,
        value: e.target.value,
        type: e.target.type
      }
    }
    if (this.state.typing) {
       clearTimeout(this.state.typingTimeout);
    }
    const valueObj = {};
    valueObj[eValue.target.name] = eValue.target.value;
    this.setState({
       value: valueObj,
       typing: true,

       //delay changes that user will be able to set full time value
       typingTimeout: setTimeout(function () {
        that._on_change(eValue);
       }, 2000)
    });
  }

  _on_change (e) {
    const { formdata } = this.state;
    let { name, value, type } = e.target;
    if(type === 'number') {
      value = Number(value);
    } else if(type === 'checkbox')
      value = e.target.checked;

    let newFormdata = {...formdata};
    const nameArr = name.split('-');
    let nameField = nameArr[0];
    let numStage = nameArr[3];
    let typeField = nameArr[4];

    if (name.includes('-N/A-')) {
      nameField = nameArr[0];
      numStage = nameArr[2];
      typeField = nameArr[3];
    } 

    if (nameField === 'schedule_da_deliver') {
      if(value !== '') {
        if(value.includes(':')) {
          if(value[value.length - 1] === ':') {
            value = this._schedule_display2api(value + '00')
          } else {
            value = this._schedule_display2api(value)
          }
        } else {
          value = this._schedule_display2api(value + ':00')
        }
      } else {
        value = null;
      }
    }
    const entryOver = newFormdata.optimization_job.entry_over;

    if(nameField === 'beta') {
      let pMaxValue = newFormdata.optimization_job?.['Pmax'];
      if(entryOver) {
        if(newFormdata.optimization_job.entry_over[0].hasOwnProperty('pMax')) {
          pMaxValue = newFormdata.optimization_job.entry_over[0]?.['Pmax'];
        }
      }
      value = value / pMaxValue;
    }

    if(nameField === 'base_ratio') {
      value = value * 100;
    }

    if(entryOver) {
      if(typeField !== 'copy') { //regular fileld
        if(!newFormdata.optimization_job.entry_over.hasOwnProperty(numStage)) {
          newFormdata.optimization_job.entry_over[numStage] = {};
        }

        if (nameField === 'deliver_nominations') {
          Object.keys(newFormdata.optimization_job.entry_over).forEach(el => {
            if (!Object.keys(newFormdata.optimization_job.entry_over[el]).includes('deliver_nominations')) 
              newFormdata.optimization_job.entry_over[el].deliver_nominations = newFormdata.optimization_job.deliver_nominations;
          })
        }

        newFormdata.optimization_job.entry_over[numStage][nameField] = value;
      } else { // value of the field should be the same in each stage
        Object.keys(newFormdata.optimization_job.entry_over).forEach((stage) => {
          if(!newFormdata.optimization_job.entry_over.hasOwnProperty(stage)) {
            newFormdata.optimization_job.entry_over[stage] = {};
          }
          newFormdata.optimization_job.entry_over[stage][nameField] = value;
        })
      }
    } else {
      newFormdata.optimization_job[nameField] = value;
    }

    let formatedNewFormdata = {...newFormdata};
    if(formatedNewFormdata.optimization_job?.entry_over !== null) {
      formatedNewFormdata.optimization_job = {...formatedNewFormdata.optimization_job, ...formatedNewFormdata.optimization_job.entry_over[numStage]};
      
    }

    this.setState({
      formdata: newFormdata,
      typing: false
    })
    // if (this.calc_limits_timer === null && !this.props.battery) {
    //   this.calc_limits_timer = window.setTimeout(function () {
    //     this.calc_limits(this.state.park.id, formatedNewFormdata, numStage, this._calc_limits_cb)
    //   }.bind(this), CALC_LIMITS_TIMEOUT);
    // }
  }

  _on_schedule_change (e) {
    const { formdata } = this.state;
    const { name, value } = e.target;
    let newFormdata = {...formdata};
    const nameArr = name.split('-');
    let numStage = nameArr[3];
    if (name.includes('-N/A-')) {
      numStage = nameArr[2];
    }
    const newSchedule = newFormdata.schedule !== null
      ? newFormdata.schedule.split(';') 
      : newFormdata.optimization_job.entry_over 
        ? Object.keys(newFormdata.optimization_job.entry_over).map((key) => null) 
        : [null];
    newSchedule[numStage] = this._schedule_display2api(value);
    newFormdata.schedule = newSchedule.join(';');
    this.setState({
      formdata: newFormdata
    })
  }

  renderForm() {
    const { formdata } = this.state; 
    const { classes } = this.props;
    if(formdata) {
      let numStages = 1;
      const entryOver = formdata.optimization_job.entry_over;
      if(entryOver) {
        numStages = Object.keys(entryOver).length;
      };
      const renderedForm = [];
      
      const schedule = formdata.schedule !== null 
        ? formdata.schedule.split(';') 
        : formdata.optimization_job.entry_over 
          ? Object.keys(formdata.optimization_job.entry_over).map((key) => null)
          : [null];
      for(let i = 0; i < numStages; i++) {
        let nameStage = this.state.park.stages.optimization_job?.[i] ? this.state.park.stages.optimization_job[i][0] : 'N/A';
        const ilp = { shrink: true };
        const stageForm = (
          <Grid item xs={12} sm={12} md={6} key={i}>
            <FormLabel>{nameStage}</FormLabel>
              <FormGroup>
                <Tooltip title="Fixed nomination limit">
                  <TextField
                    className={this.props.classes.textField}
                    type="number"
                    name={`Pmax-${nameStage}-${i}`}
                    label="Nomination limit (MW)"
                    value={this.calcFiledValue('Pmax', i)}
                    InputLabelProps={ilp}
                    margin="normal"
                    onChange={this._on_change}
                  />
                </Tooltip>
                <Tooltip title={
                  <div>Constant level of actual/forecasted generation:
                    <ul>
                      <li>use positive floating number for virtual parks</li>
                      <li>enter -1 for parks that use actual generation and forecasts</li>
                    </ul>
                  </div>}>
                  <TextField
                    className={this.props.classes.textField}
                    type="number"
                    name={`fixed_generation-${nameStage}-${i}`}
                    label="Constant level of generation (MW)"
                    value={this.calcFiledValue('fixed_generation', i)}
                    InputLabelProps={ilp}
                    margin="normal"
                    onChange={this._on_change}
                  />
                </Tooltip>

                <Tooltip title="Floating nomination limit, defined as % of DA forecast">
                  <TextField
                    className={this.props.classes.textField}
                    type="number"
                    label="Nomination limit (% of DA forecast)"
                    name={`da_forecast_limit-${nameStage}-${i}`}
                    disabled={this.calcFiledValue('da_forecast_limit', i) >= 0}
                    value={this.calcFiledValue('da_forecast_limit', i)}
                    InputLabelProps={ilp}
                    margin="normal"
                    onChange={this._on_change}
                  />
                </Tooltip>

                <Tooltip title="Floating nomination limit, defined as % of installed capacity">
                  <TextField
                    className={this.props.classes.textField}
                    type="number"
                    label="Nomination limit (% of capacity)"
                    name={`capacity_limit-${nameStage}-${i}`}
                    disabled={this.calcFiledValue('fixed_generation', i) >= 0}
                    value={this.calcFiledValue('capacity_limit', i)}
                    InputLabelProps={ilp}
                    margin="normal"
                    onChange={this._on_change}
                  />
                </Tooltip>

                <Tooltip title="Time at which ID trading starts, ID trading will start X hours before delivery starts (namely X hours before 00:00 CET/CEST)">
                  <TextField
                    className={this.props.classes.textField}
                    type="number"
                    label="Start of ID trading on D-1 (h)"
                    name={`hours_before_delivery-${nameStage}-${i}`}
                    disabled={true}
                    value={this.calcFiledValue('hours_before_delivery', i)}
                    InputLabelProps={ilp}
                    margin="normal"
                    onChange={this._on_change}
                  />
                </Tooltip>

                <Tooltip title="Maximum horizon (hours) for which optimization will provide nominations">
                  <TextField
                    className={this.props.classes.textField}
                    type="number"
                    label="Maximum horizon of ID trading (h)"
                    name={`max_afternoon_horizon-${nameStage}-${i}`}
                    disabled={true}
                    value={this.calcFiledValue('max_afternoon_horizon', i)}
                    InputLabelProps={ilp}
                    margin="normal"
                    onChange={this._on_change}
                  />
                </Tooltip>

                <Tooltip title="Schedule of DA optimisation (Internal trickery)">
                  <TextField
                    className={this.props.classes.textField}
                    type="text"
                    label="Schedule of optimisations"
                    name={`schedule-${nameStage}-${i}`}
                    value={this._schedule_api2display(schedule[i])}
                    InputLabelProps={ilp}
                    margin="normal"
                    onChange={this._on_schedule_change}
                  />
                </Tooltip>

                <Tooltip title="Schedule of sending nominations">
                  <TextField
                    className={this.props.classes.textField}
                    type="text"
                    label="Schedule of sending nominations"
                    name={`schedule_da_deliver-${nameStage}-${i}`}
                    value={
                      this.state.typing ? this.state.value[`schedule_da_deliver-${nameStage}-${i}`] :
                        this.calcFiledValue('schedule_da_deliver', i) 
                        ? this._schedule_api2display(this.calcFiledValue('schedule_da_deliver', i))
                        : ''
                    }
                    InputLabelProps={ilp}
                    margin="normal"
                    onChange={this._on_schedule_da_nom_change}
                  />
                </Tooltip>

                <FormControl>
                  <InputLabel
                    className={this.props.classes.checkboxLabel}
                    htmlFor="deliver_nominations"
                    shrink={true}
                    >Delivery of nominations to customer</InputLabel>

                  <div className={this.props.classes.useDaLimitAsGlobalDiv}>
                    <Tooltip title="If true, nominations will be sent to customer, if false, nominations will not be sent to the customer">
                        <Checkbox
                          classes={{root: this.props.classes.checkBoxRoot}}
                          id="deliver_nominations"
                          name={`deliver_nominations-${nameStage}-${i}`}
                          checked={this.calcFiledValue('deliver_nominations', i)}
                          color="primary"
                          size="small"
                          onChange={this._on_change}
                        />
                    </Tooltip>
                  </div>
                </FormControl>

                <Tooltip title={
                  <dl>
                    <p>Definition of the net open position based on the
                    number of optimizations remaining for each
                    specific contract:</p>

                    <dt><em>global</em></dt>
                    <dd>constant limit for all the stages from DA to IB</dd>

                    <dt><em>base</em></dt>
                    <dd>
                      considers the market liquidity, defined as
                      a curve having a maximum two hours before
                      delivery and exponentially decreasing with
                      bigger horizons
                    </dd>

                    <dt><em>unlimited</em></dt>
                    <dd>
                      ID trading will be limited only by the
                      single nomination limit, but open position can
                      be any
                    </dd>

                    <dt>other</dt>
                    <dd>
                      defined as <em>&#123;"hour": extra_limit&#125;</em>, for
                      example &#123;"2": 0, "3": 0, "4": 0&#125;. Hour is hour before
                      delivery and extra limit is the limit compared
                      to the ID limit Pmax
                    </dd>
                  </dl>}>
                  <TextField
                    className={this.props.classes.textField}
                    type="text"
                    label="Net Open position curve"
                    name={`liquidity_curve-${nameStage}-${i}`}
                    value={this.calcFiledValue('liquidity_curve', i, numStages)}
                    InputLabelProps={ilp}
                    margin="normal"
                    onChange={this._on_change}
                  />
                </Tooltip>

                <FormControl>
                  <InputLabel
                    className={this.props.classes.selectLabel}
                    htmlFor="mode">Optimization mode</InputLabel>
                  <Tooltip title="DA mode">
                    <Select
                      id="mode"
                      name={`mode-${nameStage}-${i}`}
                      value={this.calcFiledValue('mode', i)}
                      margin="none"
                      className={this.props.classes.selectField}
                      onChange={this._on_change}
                    >
                      <MenuItem value="deterministic">Deterministic</MenuItem>
                      <MenuItem value="raw_forecast">Raw forecast</MenuItem>
                    </Select>
                  </Tooltip>
                </FormControl>

                <FormControl>
                  <InputLabel
                    className={this.props.classes.checkboxLabel}
                    htmlFor="use_da_limit_as_global_0"
                    shrink={true}
                    disabled={true}
                    >ID limit based on DA position</InputLabel>

                  <div className={this.props.classes.useDaLimitAsGlobalDiv}>
                    <Tooltip title="DA position is used as base point to apply ID limit">
                        <Checkbox
                          classes={{root: this.props.classes.checkBoxRoot}}
                          id="use_da_limit_as_global_0"
                          name={`use_da_limit_as_global_0-${nameStage}-${i}`}
                          checked={this.calcFiledValue('use_da_limit_as_global', i) >= 0}
                          color="primary"
                          size="small"
                          disabled={true}
                          onChange={this._on_change}
                        />
                    </Tooltip>
                    <Tooltip title="Exceed DA position">
                        <Checkbox
                          classes={{root: this.props.classes.checkBoxRoot}}
                          id="use_da_limit_as_global_1"
                          name={`use_da_limit_as_global_1-${nameStage}-${i}`}
                          checked={this.calcFiledValue('use_da_limit_as_global', i) === 1}
                          color="primary"
                          size="small"
                          disabled={true}
                          onChange={this._on_change}
                        />
                    </Tooltip>
                  </div>
                </FormControl>
                <FormControl>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item>
                      <FormControl
                        className={classNames(
                          classes.formControl,
                          classes.fullWidth,
                          classes.marginBottom
                        )}
                      >
                        {this.state.useScheduleApply[i] ? 
                            <TextField
                              className={this.props.classes.textField}
                              type="date"
                              name={`future_over-${nameStage}-${i}`}
                              value={this.state.scheduleDate[i] === undefined ? null : this.state.scheduleDate[i]}
                              disabled={this.state.useScheduleApply[i] === undefined ? true : !this.state.useScheduleApply[i]}
                              onChange={this.handleChangeSchField}                               
                              label="DA schedule on delivery:"
                            />  
                          :
                            <TextField
                              className={this.props.classes.textField}
                              type="text"
                              value="Not Scheduled"
                              label="DA schedule on delivery:"
                              disabled={true}
                            />                                      
                        }
                      </FormControl> 
                    </Grid>
                    <Grid item >
                      <FormControl>
                        <Checkbox
                            classes={{root: this.props.classes.checkBoxRoot}}
                            id="use_schedule_apply"
                            name={`use_schedule_apply-${nameStage}-${i}`}
                            checked={this.state.useScheduleApply[i] === undefined ? false : this.state.useScheduleApply[i]}
                            color="primary"
                            size="small"
                            onChange={this.handleChangeCheckboxSch}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </FormControl>
              </FormGroup>
          </Grid>
        )
        renderedForm.push(stageForm);
      }
      return renderedForm;
    } else return <></>;

  };

  calcFiledValue(paramName, stage) {
    const { formdata } = this.state;
    if(formdata.optimization_job.entry_over && paramName in formdata.optimization_job.entry_over?.[stage]) {
      return formdata.optimization_job.entry_over[stage][paramName];
    }
    else
      return formdata.optimization_job[paramName];
  };


  _reset_calc_limits_timer() {
    if (this.calc_limits_timer !== null) {
      window.clearTimeout(this.calc_limits_timer);
      this.calc_limits_timer = null;
    } else {
    }
  }

  _market_auction_hour(park, dt) {
    var auction_hour = 12;
    try {
      auction_hour = park.market.auctions[dt >= 1 ? dt.toFixed(1) : dt];
      if (!auction_hour) {
        auction_hour = park.market.auctions['1.0'];
      }
    } catch (error) {
      console.error('failed to obtain auction hour, error', error);
    }
    return auction_hour;
  }

  _post_process_da_limits(base, park) {
    // const mindt = Math.min(
    //   park.optimization_job_da ? park.optimization_job_da.dt : 1.0,
    //   park.optimization_job_id ? park.optimization_job_id.dt : 1.0);
    const dt = park.optimization_job_da.dt;
    // negate
    base.forEach((i, idx, a) => a[idx] = 0 - i);

    // nullify unreachable DA
    // const day_contracts = Math.round(24 / mindt);
    const day_contracts = Math.round(24 / dt);
    // const dt = park.optimization_job_da.dt;
    let auction_hour = this._market_auction_hour(park, dt);

    let null_contracts =
      day_contracts - Math.round((auction_hour - 1) / dt);
    base.fill(null, 0, null_contracts);
    base.reverse();
    base.name = this.Base;
  }

  /**
   * Prepare optimization job parameters for API
   */
  fixup_optimization_job_parameters(params, for_submit=false) {
    params.liquidity_curve = JSON.parse(params.liquidity_curve);

    if (params.fixed_generation < 0) {
      params.fixed_generation = null;
    };

    if (params.mode === '') {
      params.mode = null;
    }

    if (params.intraday_treatment === '') {
      params.intraday_treatment = null;
    }

    params.use_da_limit_as_global = this._use_da_limit_display2api(
      params.use_da_limit_as_global);

    if (for_submit) {
      delete params.afternoon_run;
      delete params.dt;
    };
  };

  put_park(park, formdata, cb) {
    var data = {};
    let tmp = {};
    
    const scheduleArr = formdata.schedule ? formdata.schedule.split(';') : [];
    let newSchedule = [];
    let scheduleEntryOvers = [];
    let noScheduleEntryOvers = [];
    let newFormdata = JSON.parse(JSON.stringify(formdata));
    
    if (scheduleArr.length > 1) {
      scheduleArr.forEach((el, i) => {
        if (!el) {
          noScheduleEntryOvers.push(formdata.optimization_job.entry_over[i])
        } else {
          newSchedule.push(el);
          scheduleEntryOvers.push(formdata.optimization_job.entry_over[i])
        }
      })

      const entryOvers = [...scheduleEntryOvers, ...noScheduleEntryOvers];
      entryOvers.forEach((el, i) => {
        newFormdata.optimization_job.entry_over[i] = el;
      })
      newFormdata.schedule = newSchedule.filter(el => el).length ? newSchedule.join(';') : null
    } else {
      if (!scheduleArr[0]) {
        newFormdata.schedule = null
      }
    }
    
    try {
      if (newFormdata !== null) {
        tmp = Object.assign({}, newFormdata.optimization_job);
        this.fixup_optimization_job_parameters(tmp, true);
        data.optimization_job = tmp;
      }

      data.schedule = newFormdata.schedule;

      cb(true);
    } catch (error) {
      alertify.error('Parameter validation error: ' + error, 5);
      console.log('param validation error', error);
      cb(false);
      return;
    };

    const [url, headers] = buildAPIRequest('/api/v1/parks/' + park.id);
    fetch(url, {
      signal: this.abrt.signal,
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify(data), ...security_fetch_params
    }).then(function (response) {
        if (response.ok) {
          response.json().then(data => {
            if (data.status === "success") {
              alertify.success(
                'Park was updated successfuly', 5);
              cb(true);
            }
          });
        } else if (response.status === 401) {
          logout();
          return;
        } else {
          if (response.status >= 400 && response.status < 500) {
            response.json().then(function (data) {
              alertify.error('API call error:' + data.error, 5);
              console.log('parks response error', data);
              cb(false);
            }).catch(error => {
              alertify.error('API json error', 5);
              console.log('parks json error', error);
              cb(false);
            });
          } else {
            alertify.error('parks API error', 5);
            console.log(response);
            cb(false);
          }
        };
      }).catch(error => {
        alertify.error(
          'Failed to update park, error: ' + error, 5);
        cb(false);
      });
  }


  // XXX compare to Optimizations and Forecast, eventually factor out
  load_park(park_id, cb) {
    const [url, headers] = buildAPIRequest('/api/v1/parks/' + park_id);
    const that = this;
    fetch(url, {
      signal: this.abrt.signal,
      method: 'GET',
      headers: headers, ...security_fetch_params
    }).then(function (response) {
      if (response.ok) {
        response.json().then(data => {
          if (data.error) {
            alertify.error("Response error");
            console.log(data.error);
            return
          }
          data.updated_at = moment().unix();
          localForage.setItem(
            `park_data_${park_id}_${that.props.conn.label}`,
            data,
            function (err) {
              // if err is non-null, we got an error
              if (err) {
                console.log('localForage.setItem error', err);
              }
            }
          );

          if (data.data) {
            cb(data.data);
          } else {
            cb(null);
          };
        }).catch(error => {
          console.log(error);
          cb(null);
        });
      } else if (response.status === 401) {
        logout();
        return;
      } else {
        cb(null);
      }
    }).catch((error) => {
      console.log(error);
      cb(null);
    });
  };


  get_park_data(park_id, cb, force=false) {
    localForage.getItem(
      `park_data_${park_id}_${this.props.conn.label}`,
      function (err, value) {
        if (value != null) {
          if ((value.updated_at < (moment().unix() - LF_CACHE_TTL)) || force) {
            this.load_park(park_id, cb);
          } else {
            if (!value.data) {
              cb(null);
            } else {
              cb(value.data);
            }
          }
        } else {
          this.load_park(park_id, cb);
        }
      }.bind(this)
    );
  };


  get_limits(park, cb) {
    const [url, headers] = buildAPIRequest('/api/v1/parks/' + park.id + '/limits');
    fetch(url, {
      signal: this.abrt.signal,
      method: 'GET',
      headers: headers, ...security_fetch_params
    }).then(function (response) {
      if (response.ok) {
        response.json().then(function (data) {
          if (data.error) {
            alertify.error("Response error");
            console.log(data.error);
            return
          }
          if(data.status === 'success')
            cb(this.calcLimitsResponse(data.data, park), data.data);
        }.bind(this)).catch(error => {
          alertify.error('get_limits json error', 5);
          console.log(error);
          cb(null);
        });
      } else if (response.status === 401) {
        logout();
        return;
      } else {
        if (response.status >= 400 && response.status < 500) {
          response.json().then(function (data) {
            alertify.error('API call error:' + data.error, 5);
            console.log('get_limits response error', data);
            cb(null);
          }).catch(error => {
            alertify.error('API json error', 5);
            console.log('get_limits json error', error);
            cb(null);
          });
        } else {
          alertify.error('get_limits API error', 5);
          console.log(response);
          cb(null);
        }
      };
    }.bind(this)).catch(error => {
        alertify.error(
          'Failed to get limits, error: ' + error, 5);
        cb(null);
    });
  };

  calcLimitsResponse = (res, park) => {
    let auBase = [];
    let idBase = [];
    let idOp = [];
    const auKeys = [];
    const idKeys = [];
    const auDt = [];
    const idDt = [];
    const responseLimits = JSON.parse(JSON.stringify(res));
    Object.keys(responseLimits).forEach((key) => {
      if(key.includes('AU')){
        auKeys.push(key)
      } else if(key.includes('ID')){
        idKeys.push(key)
      }
    });
    if(auKeys.length){
      auKeys.forEach((key) => {
        if(responseLimits[key].base_limits){
          auBase.push(responseLimits[key].base_limits)
        }
        auDt.push(park.optimization_job.dt)
      });
    }
    if(idKeys.length){
      idKeys.forEach((key) => {
        if(responseLimits[key].base_limits){
          idBase.push(responseLimits[key].base_limits)
        }
        if(responseLimits[key].open_position_limits){
          idOp.push(responseLimits[key].open_position_limits)
        }
        idDt.push(park.optimization_job.dt)
      });
    }
    
    // Check if stage have specific dt
    const auEntries = [];
    const idEntries = [];
    const entryOver = park?.optimization_job.entry_over;
    const dt = [];
    if(entryOver){
      Object.keys(entryOver).forEach((entry) => {
        if (!entryOver[entry].afternoon_run)
          auEntries.push(entryOver[entry])
        else
          idEntries.push(entryOver[entry])
        entryOver[entry].dt ? dt.push(entryOver[entry].dt) : dt.push(park.optimization_job.dt)
      });
    }
    else {
      dt.push(park.optimization_job.dt);
    }
    auEntries.forEach((entry,id) => {
      if (entry.dt) {
        auDt[id] = entry.dt;
      }
    });
    idEntries.forEach((entry,id) => {
      if (entry.dt) {
        auDt[id] = entry.dt;
      }
    });
    
    const minDt = Math.min(...auDt, ...idDt);

    // splice da_base
    let auBaseSplice = [];
    auBase.forEach((el,id) => {
      if (el.length && auDt[id] > minDt) {
        const r = Math.round(auDt[id] / minDt);
        let tmp = Array(el.length * r).fill(null).map(
          (i, idx) => (idx % r === 0 ? el[Math.round(idx / r)] : null)
        );
        auBaseSplice.push(tmp);
        auBase[id] = tmp;
      }
    });

    const max_contracts = Math.max(...[...auBase.map(el=>el.length), ...idBase.map(el=>el.length)]);

    const labels = Array(max_contracts).fill(null).map(
      (i, idx) => 'H:' + (1 + idx * minDt));

    const limits = {
      'series': [],
      'labels': labels.reverse(),
      'dt': dt,
    }
    const day_contracts = Math.round(24 / minDt);
    if(auBase.length){
      auBase.forEach((el,id) => {
        const auction_hour = this._market_auction_hour(park, auDt[id]);
        const null_contracts = day_contracts - Math.round((auction_hour - 1) / minDt);

        el.fill(null, 0, null_contracts);
        el.name = auKeys[id];
        let limitsValues = el.map(val => val / auDt[id]);
        limitsValues.name = `${auKeys[id]}`;
        limits.series.push(limitsValues.reverse());
      });
    }
    if(idBase.length){
      // nullify unreachable ID   
      idBase.forEach((el,id) => {
        const null_contracts = max_contracts - Math.round(el.length / minDt);
        if(null_contracts > 0){
          el.splice(Math.round(el.length / minDt), 0, ...Array(null_contracts).fill(null));
          if(idOp.length){
            idOp[id].splice(Math.round(idOp[id].length / minDt), 0, ...Array(null_contracts).fill(null));
          }
        }
        idOp[id].name = `${idKeys[0]} (open position)`;
        limits.series.push(idOp[id].reverse());
        el.name = `${idKeys[0]} (base)`;
        limits.series.push(el.reverse());
      });
    }
    return limits;
  }


  calc_limits(park_id, params, stage, cb) {
    const [url, headers] = buildAPIRequest('/api/v1/parks/' + park_id + '/calclimits');
    params = Object.assign({}, params.optimization_job);
    try {
      // XXX fix up ...
      this.fixup_optimization_job_parameters(params);

    } catch (error) {
      alertify.warning('Error: ' + error.error, 2);
      cb(null);
      return;
    }
    fetch(url, {
      signal: this.abrt.signal,
      method: 'POST',
      headers: headers,
      body: JSON.stringify(params), ...security_fetch_params,
    }).then(function (response) {
      if (response.ok) {
        response.json().then(function (data) {
          // post-process limits
          cb(data,stage);
        }).catch((error) => {
          alertify.error('API json error', 5);
          console.log('calc_limits json error', error);
          cb(null);
        });

      } else if (response.status === 401) {
        logout();
        return;
      } else {
        if (response.status >= 400 && response.status < 500) {
          response.json().then(function (data) {
            alertify.error('API call error:' + data.error, 5);
            console.log('calc_limits response error', data);
            cb(null);
          }).catch(error => {
            alertify.error('API json error', 5);
            console.log('calc_limits json error', error);
            cb(null);
          });
        } else {
          alertify.error('API server error:' + response.statusText, 5);
          console.log('calc_limits response error', response);
          cb(null);
        }
      }

    }).catch((error) => {
      alertify.error('API fetch error', 5);
      console.log('calc_limits fetch error', error);
      cb(null);
    });
  };

  _calc_limits_cb(limits, stage) {
    if (limits !== null) {
      // change limits in responseLimits to new limits
      const newResponseLimits = {...this.state.responseLimits};
      const nameResLimitsObject = Object.keys(newResponseLimits)[stage];
      if(newResponseLimits[nameResLimitsObject].base_limits){
        newResponseLimits[nameResLimitsObject].base_limits = limits.base_limits;
      }
      if(newResponseLimits[nameResLimitsObject].open_position_limits){
        newResponseLimits[nameResLimitsObject].open_position_limits = limits.open_position_limits;
      }
      const newLimits = this.calcLimitsResponse({...newResponseLimits}, this.state.park);
      this.setState({limits: newLimits}, function () {
        var ojlim = document.getElementById('ojlim');
        this._reset_calc_limits_timer();
        this._show_complete(ojlim);
      }.bind(this));
    } 
  }

  _initial_load() {
    try {
      // Use first park as initial
      const park_id = this.state.options[0].id;
      const pform = document.getElementById('pform0');

      // wait?
      this._show_waiting(pform);
      this._setup_park(park_id, true, () => {this._show_complete(pform)});

    } catch (error) {
      console.log('error handling parks API' + error);
    };
  }

  componentDidMount() {
    if (this.props.conn.parks.length > 0 && !this.state.park) {
      this._initial_load();
    }
  }

  componentWillUnmount() {
    this.abrt.abort();
  };

  shouldComponentUpdate(nextProps, nextState) {
    const want_update = (
      (this.state.park !== nextState.park)
      || (this.state.formdata !== nextState.formdata)
      || (this.state.limits !== nextState.limits)
      || (this.props.conn !== nextProps.conn)
      || (this.state.scheduleDate !== nextState.scheduleDate)
      || (this.state.useScheduleApplyDa !== nextState.useScheduleApplyDa)
      || (this.state.useScheduleApplyId !== nextState.useScheduleApplyId)
      || (this.state.value !== nextState.value)
    );
    return want_update;
  };

  // Use componentDidUpdate as componentDidMount is too early
  // and there's no parks loaded
  componentDidUpdate() {
    // Run only once when park isn't set
    if (this.props.conn.parks.length > 0 && !this.state.park) {
      this._initial_load();
    }
  };

  _show_waiting(elm) {
    if (elm !== null) {
      elm.style.pointerEvents = 'none';
      elm.style.opacity = '0.43';
      elm.style.transition = '0.3s';
    }
  }


  _show_complete(elm) {
    if (elm !== null) {
      elm.style.pointerEvents = '';
      elm.style.opacity = '';
      elm.style.transition = '0.3s';
    }
  }

  _on_park_select(_, value) {
    if (!value) return;

    const pform = document.getElementById('pform1');
    // wait?
    this._show_waiting(pform);
    this._setup_park(
      value.id,
      false,
      () => { this._show_complete(pform) },
    );
  }

  _on_submit(ev) {
    var pform = document.getElementById('pform0');

    const curScheduleDate =
      this.state['formdata']?.future_over &&
        Object.keys(this.state['formdata']?.future_over)[0];
    if (curScheduleDate)
      if (
        moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').unix() >=
        moment(curScheduleDate, 'YYYY-MM-DD').add(-1, 'days').unix()
      ) {
        alertify.error(
          'DA Limit will not affect because of schedule existing',
          10
        );
      }

    // wait?
    this._show_waiting(pform);
    this.put_park(
      this.state.park,
      this.state.formdata,
      function (success) {
        if (success) {
          this._setup_park(this.state.park.id, true, () => {this._show_complete(pform)});
        } else {
          this._show_complete(pform);
        };
      }.bind(this)
    );
  };

  _on_schedule_apply() {
    var pform = document.getElementById('pform0');
    const formDataStart = { ...this.state.formDataStart };
    const entryOver = formDataStart.optimization_job.entry_over;
    let future_over = null;  // new value of future_over for the stage
    Object.keys(this.state.useScheduleApply).forEach((key) => {
      let curScheduleDate; // current value of future_over for the stage
      future_over = {};
      if (!entryOver) { // if there is no entry_over then take data from base object
        curScheduleDate =
          formDataStart.optimization_job.future_over &&
          Object.keys(formDataStart.optimization_job.future_over)[0];
        future_over[this.state.scheduleDate[key]] = {
          Pmax: this.state.formdata.optimization_job.Pmax,
        };
      }
      else {  // if there is entry_over then take data from it
        curScheduleDate =
          formDataStart.optimization_job.entry_over[key].future_over &&
          Object.keys(formDataStart.optimization_job.entry_over[key].future_over)[0];
        if(entryOver[key].Pmax) // if Pmax is set in entry_over then take it
          future_over[this.state.scheduleDate[key]] = {
            Pmax: this.state.formdata.optimization_job.entry_over[key].Pmax,
          };
        else { // if Pmax is not set in entry_over then take it from base object
          future_over[this.state.scheduleDate[key]] = {
            Pmax: this.state.formdata.optimization_job.Pmax,
          };
        }
      }

      //if there is previous schedule, create new schedule and set permanent Pmax as previous schedule
      if (curScheduleDate) {
        if (
          moment(curScheduleDate, 'YYYY-MM-DD').add(-1, 'days').unix() <=
          moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').unix()
        ) {
          if(entryOver?.[key]?.Pmax) 
            formDataStart.optimization_job.entry_over[key]['Pmax'] = formDataStart.optimization_job.entry_over[key].future_over[curScheduleDate]['Pmax'];
          else 
            formDataStart.optimization_job['Pmax'] = formDataStart.optimization_job.future_over[curScheduleDate]['Pmax'];
        }
      }
      if(entryOver?.[key]) 
        formDataStart.optimization_job.entry_over[key]['future_over'] = future_over;
      else 
        formDataStart.optimization_job['future_over'] = future_over;
    });

    // if there is no schdeule actions then set future_over to null
    if(entryOver){
      Object.keys(entryOver).forEach((key) => {
        if(!this.state.useScheduleApply[key]) {
          if(entryOver?.[key]) 
            formDataStart.optimization_job.entry_over[key]['future_over'] = null;
        }
      })
    }
    else {
      if(!this.state.useScheduleApply[0]) {
        formDataStart.optimization_job['future_over'] = null;
      }
    }

    this._show_waiting(pform);
    this.put_park(
      this.state.park,
      formDataStart,
      function (success) {
        if (success) {
          this._setup_park(this.state.park.id, true, () => {
            this._show_complete(pform);
          });
        } else {
          this._show_complete(pform);
        }
      }.bind(this)
    );
  };

  _on_reset() { 
    var pform = document.getElementById('pform0'); 
    // wait? 
    this._show_waiting(pform); 

    this.get_limits(this.state.park, function (limits, responseLimits = null) { 
      const formdata = this._form_data(this.state.park)
      var st = { 
        formdata: JSON.parse(JSON.stringify(formdata)),  
        formDataStart: JSON.parse(JSON.stringify(formdata)) 
      }; 
      if (limits !== null) { 
        st['limits'] = limits;   
        st['responseLimits'] = responseLimits;
      }

      // if(formdata) {
      //   const {keys, curSchedulePmax} = this.getCurSchPmax(st['formdata'].optimization_job);
      //   if(curSchedulePmax !== null) {
      //     if(keys === null) {
      //       st['formdata'].optimization_job.Pmax = curSchedulePmax;
      //     } else {
      //       keys.forEach((key, id) => {
      //         st['formdata'].optimization_job.entry_over[key].Pmax = curSchedulePmax[id];
      //       });
      //     }
      //   };
      // }
      this.setState(st);
      this._show_complete(pform);
    }.bind(this));
  }

  handleChangeSchField = (e) => {
    const { name, value } = e.target;
    const nameArr = name.split('-');
    const numStage = nameArr[2];
    let newScheduleDate = { ...this.state.scheduleDate };
    if(newScheduleDate && Object.keys(newScheduleDate).length){
      newScheduleDate[numStage] = value;
    } else {
      newScheduleDate = {};
      newScheduleDate[numStage] = value ;
    }
    this.setState({ scheduleDate: newScheduleDate });
  };

  handleChangeCheckboxSch = (e) => {
    const { name } = e.target;
    const nameArr = name.split('-');
    const numStage = nameArr[2];
    let newScheduleDate = { ...this.state.scheduleDate };
    
    const newUseScheduleApply = {...this.state.useScheduleApply};
    if(newUseScheduleApply[numStage])
      newUseScheduleApply[numStage] = false;
    else
      newUseScheduleApply[numStage] = true;
    newScheduleDate[numStage] = moment.tz('UTC').add(+1, 'days').format('YYYY-MM-DD') ;
    
    this.setState({
      scheduleDate: newScheduleDate,
      useScheduleApply: newUseScheduleApply,
    });
  };

  render() {
    if (this.props && this.props.isLoggedIn) {

      let activePark = this.state.options.filter(
        (item) => item.id === this.state.park?.id
      );
      activePark = activePark.length ? activePark[0] : null;

      //Collect data for schedule table
      const entryOver = this.state.formdata?.optimization_job.entry_over;
      const scheduleTableData = [];
      const scheduleTableHead = ['Num','Name stage','Schedule delivery date', 'Pmax'];
      if(this.state.formdata){
        if(entryOver){
          Object.keys(entryOver).forEach((key) => {
            let futOverDate = '-';
            let futOverValue = '-';
            const stageName = entryOver[key].afternoon_run ? 'ID' : 'AU';
            if(entryOver[key].future_over) {
              futOverDate = Object.keys(entryOver[key].future_over)[0];
              futOverValue = entryOver[key].future_over[futOverDate]['Pmax'];
            }
            scheduleTableData.push([Number(key) + 1, stageName, futOverDate, futOverValue]);
          });
        }
         else {
          const baseFutOver = this.state.formdata.optimization_job.future_over;
          let futOverDate = '-';
          let futOverValue = '-';
          const stageName = this.state.formdata.optimization_job.afternoon_run ? 'ID' : 'AU';
          if(baseFutOver){
            futOverDate = Object.keys(baseFutOver)[0];
            futOverValue = baseFutOver[futOverDate]['Pmax'];
          }
          scheduleTableData.push([1, stageName, futOverDate, futOverValue]);
        }
      }

      const schTableBody = scheduleTableData.map((row, index) => {
        return (
          <TableRow key={index}>
            {row.map((cell, index) => {
              return (
                <TableCell
                  key={index}
                  className={this.props.classes.scheduleCell}
                >
                  {cell}
                </TableCell>
              );
            })}
          </TableRow>
        );
      });

      const schTableHead = scheduleTableHead.map((prop, key) => {
        return (
          <TableCell className={this.props.classes.scheduleTableHeadCell} key={key}>
            {prop}
          </TableCell>
        );
      });
      
      const series = this.state.limits?.series.map((item, index) => {
        return {
          name: item.name,
          data: item,
          type: 'column'
        };
      });
      
      const options = {
        chart: {
          type: "column"
        },
        title: {
          text: "Limits"
        },
        series: series,
        xAxis: {
          categories: this.state.limits?.labels
        },
        yAxis: {
          title: {
              text: 'MWh',
          },
        }
      };

      const renderBatteryForm = () => {
        const { formdata } = this.state;
        if(formdata) {
          const nameStage = 'Battery';
          const i = 0;
          const ilp = { shrink: true };
            
          const batForm = (
            <Grid item xs={12}>
                <FormGroup>
                  <Tooltip title="Maximum Power (MW)">
                    <TextField
                      className={this.props.classes.textField}
                      type="number"
                      name={`Pmax-${nameStage}-${0}-copy`}
                      label="Maximum Power (MW)"
                      value={this.calcFiledValue('Pmax', i)}
                      InputLabelProps={ilp}
                      margin="normal"
                      onChange={this._on_change}
                    />
                  </Tooltip>
                  <Tooltip title="Capacity (MWh)">
                    <TextField
                      className={this.props.classes.textField}
                      type="number"
                      name={`beta-${nameStage}-${0}-copy`}
                      label="Capacity (MWh)"
                      value={this.calcFiledValue('Pmax', i) * this.calcFiledValue('beta', i)}
                      disabled={true}
                      InputLabelProps={ilp}
                      margin="normal"
                      onChange={this._on_change}
                    />
                  </Tooltip>
                  
                  <Tooltip title="Discharge/Charge ratio">
                    <TextField
                      className={this.props.classes.textField}
                      type="number"
                      label="Discharge/Charge ratio"
                      name={`base_ratio-${nameStage}-${0}-copy`}
                      value={this.calcFiledValue('base_ratio', i) / 100}
                      InputLabelProps={ilp}
                      margin="normal"
                      onChange={this._on_change}
                    />
                  </Tooltip>

                  <Tooltip title="Allowed number of cycles per day">
                    <TextField
                      className={this.props.classes.textField}
                      type="number"
                      label="Cycles per day"
                      name={`alpha-${nameStage}-${0}-copy`}
                      value={this.calcFiledValue('alpha', i)}
                      InputLabelProps={ilp}
                      margin="normal"
                      onChange={this._on_change}
                    />
                  </Tooltip>
                </FormGroup>
            </Grid>
          )
          return batForm
        } else return <></>;
      }
      
      return (
        <div id="pform0">
          <Grid container spacing={4}>
            <ParkSelector 
              country_code={this.state.park?.location?.country_iso3166}
              country_name={this.state.park?.location?.country_name}
              options={this.state.options}
              selected={activePark}
              handleChange={this._on_park_select}
              capacity={this.state.park ? this.state.park.capacity.toFixed(1) : 'N/A'}
            />
            <Grid item xs={12} sm={12} id="pform1">
              {
                this.props.battery && (
                  <Card>
                  <CardHeader color="primary">
                    <h4 className={this.props.classes.cardTitleWhite}>{this.props.battery ? 'Battery specifications' : 'Edit Limits'}</h4>
                    <p className={this.props.classes.cardCategoryWhite}>
                      {this.state.park ? sprintf.sprintf('%s (%s)', this.state.park.name, this.state.park.id) : 'N/A'}
                    </p>
                  </CardHeader>
                  <CardBody>
                    <div id="ojlim"></div>
                    <div id="ojlimlg" className="ojlimlg"></div>
                    {!this.props.battery && (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                      />
                    )}
                    <FormControl>
                      <Grid container spacing={4} justifyContent="center">
                        {this.props.battery && renderBatteryForm()}
                        <Grid item xs={12} sm={12} md={12}>
                          <RegularButton
                            type="submit"
                            color="primary"
                            onClick={this._on_submit}
                            disabled={this.state.typing}
                          >
                            Submit Data
                          </RegularButton>
  
                          <RegularButton
                            type="reset"
                            color="primary"
                            onClick={this._on_reset}
                          >
                            Reset Form
                          </RegularButton>
                        </Grid>
                      </Grid>
                    </FormControl>
                  </CardBody>
                </Card>
                )
              }
              <Card>
                <CardHeader color="primary">
                  <h4 className={this.props.classes.cardTitleWhite}>{this.props.battery ? 'Optimisation settings' : 'Edit Limits'}</h4>
                  <p className={this.props.classes.cardCategoryWhite}>
                    {this.state.park ? sprintf.sprintf('%s (%s)', this.state.park.name, this.state.park.id) : 'N/A'}
                  </p>
                </CardHeader>
                <CardBody>
                  <div id="ojlim"></div>
                  <div id="ojlimlg" className="ojlimlg"></div>
                  {!this.props.battery && (
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={options}
                    />
                  )}
                  <FormControl>
                    <Grid container spacing={4} justifyContent="center">
                      {this.renderForm()}
                      <Grid item xs={12} sm={12} md={12}>
                        <RegularButton
                          type="submit"
                          color="primary"
                          onClick={this._on_submit}
                          disabled={this.state.typing}
                        >
                          Submit Data
                        </RegularButton>

                        <RegularButton
                          type="reset"
                          color="primary"
                          onClick={this._on_reset}
                        >
                          Reset Form
                        </RegularButton>
                        <RegularButton
                          color="primary"
                          onClick={this._on_schedule_apply}
                        >
                          Update Schedule
                        </RegularButton>
                      </Grid>
                      <Grid item xs={12} sm={12}  md={12}>
                        <Table className={this.props.classes.scheduleTable}>
                          <colgroup>
                              <col width="25%" />
                              <col width="25%" />
                              <col width="25%" />
                              <col width="25%" />
                          </colgroup>

                          <TableHead>
                            <TableRow>
                              {schTableHead}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {schTableBody}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </FormControl>
                </CardBody>
              </Card>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return (<LoginPage />);
    }
  };
}


const ConnectedConfig = connect(mapStateToProps, mapDispatchToProps)(Config);
export default withStyles(styles)(ConnectedConfig);

// vim: tabstop=2 shiftwidth=2 softtabstop=2 expandtab
